@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        overflow-x: hidden;
        filter: blur(0px);
        scroll-behavior: smooth;
    }

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
    }

    body {
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }

    .bg-image::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .intro-text {
        position: absolute;
        top: 50%;
        bottom: 50%;
        width: 100%;
        left: translate(50%, 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .slowzoom {
        animation: zoom 30s infinite ease-in-out;
    }

    ;

    @keyframes zoom {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.2, 1.2);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #43d1c8;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }
}